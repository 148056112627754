import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  html {-webkit-text-size-adjust:100%;line-height:1.15}
  body {margin:0;font-family: 'Nunito Sans', 'Pretendard', sans-serif;background-color: #fff; color: #222;}
  *{box-sizing:border-box;}
  h1, h2, h3, h4, h5, h6 {font-size:1em;font-weight:400;margin:0}
  p {margin: 0;}
  hr {box-sizing:content-box;height:0;overflow:visible}
  pre {font-family:monospace, monospace;font-size:1em}
  a {color:inherit;background-color:transparent;text-decoration:none;cursor:none;}
  b, strong {font-weight:bolder}
  ul, li, ol{list-style:none;padding:0;margin:0;}
  img {border-style:none;max-width:100%;}
  button, input, optgroup, select, textarea {font-family:inherit;font-size:100%;line-height:1.15;margin:0}
  button, input {overflow:visible}
  button, select {text-transform:none}
  [type=button], [type=reset], [type=submit], button {-webkit-appearance:button}
  [type=button]::-moz-focus-inner, [type=reset]::-moz-focus-inner, [type=submit]::-moz-focus-inner, button::-moz-focus-inner {border-style:none;padding:0}
  textarea {overflow:auto}
  [type=checkbox], [type=radio] {box-sizing:border-box;padding:0}
  
  #root{cursor:none;}
  .no-scroll{position: fixed; width: 100%;}
  .wrap .scrollbar-track{background: rgba(222, 222, 222, .5);}
  .wrap .scrollbar-thumb{background: rgba(0, 0, 0, .3);}

  @media (max-width: 767px){
    #root{cursor: inherit;}
    a{cursor: pointer;}
  }
`;

export default GlobalStyle;