import React from "react";
import styled from "styled-components";

const MainWrap = styled.div`
  background: ${({theme}) => theme.color.green} url('/assets/bg_noise.png'); position: relative;
  &:after{content: ''; display: block; width: 100%; height: 100%; position: absolute; top: 0; left: 0; background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' stroke='%23fff'%3E%3Cellipse cx='50%' cy='50%' rx='40vw' ry='40vh' /%3E%3C/svg%3E"); opacity: 0.4;}
  .bottom_box{width: 100%; display: flex; justify-content: space-between; padding: 15px; color: #fff; font-size: 10px; letter-spacing: 0.04em; position: absolute; bottom: 0;}
  .content_box{padding-left: 50%; padding-top: 25vh; font-family: ${({theme}) => theme.fontFamily.garamond}; color: ${({theme}) => theme.color.point1}; font-size: 5vw;}
  @media (max-width: 767px) {
    &#home{min-height: 75vh;}
    &:after{height: 50%; top: 50%; transform: translateY(-50%)}
    .content_box{padding-top: 15vh;}
  }
`;

function Main (){
  return(
    <MainWrap className="section" id="home">
      <div className="content_box">
        <span>Fresh methods for interactive websites.</span>
      </div>
      <div className="bottom_box">
        <span>2023</span>
        <span>PORTFOLIO</span>
        <span>DOHEELEE</span>
      </div>
    </MainWrap>
  )
}
export default Main;