import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import './css/font.scss';
import Container from './components/Container';
import PopupWrap from './components/Popup';
import { gsap } from "gsap";

const Wrap = styled.div`
  position:relative; background: #fefff4 url('/assets/bg_noise.png');
  .cursor-box{position: fixed; z-index: 99999; top: 0; left: 0; mix-blend-mode: difference; pointer-events: none;
    .cursor-origin{background-color: #fff; width: 10px; height: 10px; border-radius: 100%; cursor: none;}
    .cursor-follower{position: absolute; top: 0; left: 0; border: 1px solid #fff; width: 30px; height: 30px; border-radius: 100%; transition: width 0.3s, height 0.3s;}
    &.active{
      .cursor-origin{opacity: 0;}
      .cursor-follower{width: 60px; height: 60px;}
    }
  }
  .section{min-height: 100vh; width: 100%; overflow-x: hidden;}
  .garamond{font-family: ${({theme}) => theme.fontFamily.garamond};}
  @media (max-width: 767px) {
    .cursor-box{display: none;}
    .section{min-height: 50vh;}
  }
`

function App(props) {
  const ref = useRef(null);
  
  useEffect(() => {
    let ctx = gsap.context((self) => {
      let cursorBox = '.cursor-box';
      let cursor = ".cursor-origin";
      let follower = ".cursor-follower"

      gsap.set(cursor, {xPercent: -50, yPercent: -50});
      gsap.set(follower, {xPercent: -50, yPercent: -50});

      self.add("onMove", (e)=> {
        gsap.to(cursor, {duration: 0.2, x: e.clientX, y: e.clientY, ease: "ease"});
        gsap.to(follower, {duration: 0.5, x: e.clientX, y: e.clientY, ease: "ease"});
      })
      self.add("onLeave", () => {
        gsap.to(cursorBox, {duration: 0.1, opacity: 0})
      })
      self.add("onEnter", () => {
        gsap.to(cursorBox, {duration: 0.1, opacity: 1})
      })
    }, ref);

    window.addEventListener("mousemove", (e) => ctx.onMove(e));
    document.addEventListener("mouseleave", (e) => ctx.onLeave(e));
    document.addEventListener("mouseenter", (e) => ctx.onEnter(e));
    return () => {
      ctx.revert();
      window.removeEventListener("mousemove", ctx.onMove);
      document.removeEventListener("mouseleave", ctx.onLeave);
      document.removeEventListener("mouseenter", ctx.onEnter);
    }
  }, []);
  
  const [isHover, setIsHover] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [workId, setWorkId] = useState();
  
  useEffect(() => {
    if (showPopup){
      document.body.style.cssText = `
      top: -${window.scrollY}px;`;
      document.body.classList.add('no-scroll');
    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = '';
      document.body.classList.remove('no-scroll');
      window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
    };
    }
  }, [showPopup]);

  return (
    <Wrap ref={ref}>
      <div className={`cursor-box ${isHover ? 'active':''}`}>
        <div className="cursor-origin"></div>
        <div className="cursor-follower"></div> 
      </div>
      
      <Container setWorkId={setWorkId} setIsHover={setIsHover} setShowPopup={setShowPopup} />
      {showPopup && <PopupWrap workId={workId} onClose={setShowPopup} setIsHover={setIsHover} />}
    </Wrap>
  )
}

export default App;