import { useState, useEffect } from "react";

export default function useMediaQuery(query){
  const [matches, setMatches] = useState(false);
  const media = window.matchMedia(query);
  if(media.matches !== matches){
    setMatches(media.matches);
  }

  useEffect(() => {
    const listener = () => setMatches(media.matches);
    media.addEventListener("change", listener);
    return () => media.removeEventListener("change", listener);
  }, [media]);

  return matches;
}