import { useEffect, useRef } from "react";
import styled from "styled-components";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { workList } from "../workList";
import useMediaQuery from "../hooks/useMediaQuery";

const WorkWrap = styled.div`
  overflow: hidden;
  .pin_box{
    .title_box{max-width: 1400px; margin: 0 auto; padding: 40px;
      .work__title{font-size: 36px;}
    }
    .panel_wrap{padding: 5vw 5vw 0;
      .panel_box{display: grid; grid-template-rows: 1fr 1fr; grid-template-columns: repeat(9, minmax(24vw, 1fr)); gap: 30px; grid-auto-flow: column;
        .panel{min-height: 25vh; padding: 20px; background: #fff; color: #222; transition-duration: 0.3s; position: relative; overflow: hidden;
          &:before{content: ''; display: block; width: calc(100% - 40px); height: calc(100% - 40px); position: absolute; top: 100%; left: 20px; background: #d4dfb4; transition-duration: 0.3s;}
          .panel__conts{padding: 25px; opacity: 0; transition-duration: 0.3s; position: relative; z-index: 1;
            .panel__title{margin-bottom: 10px; font-size: 18px; position: relative;}
            .panel__subtitle{font-size: 14px; color: #555; word-break: keep-all;}
          }
          .panel__logo{position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);
            svg{display: block; width: 80%; height: 50px; margin: auto;}
            img{display: block; max-width: 180px; max-height: 60px;}
          }
          &:hover{background: #fff; color: #222;
            &:before{top: 20px;}
            .panel__conts{opacity: 1;}
            .panel__logo{opacity: 0;}
          }
        }
      }
    }
    @media (max-width: 767px){
      .title_box{padding: 40px 15px;}
      .panel_wrap .panel_box{grid-template-rows: 1fr 1fr; grid-template-columns: repeat(9, minmax(60vw, 1fr));
        .panel{min-height: unset;}
      }
    }
  }
`;

function Work({setIsHover, setShowPopup, setWorkId}){
  const ref = useRef(null);
  let isDesktop = useMediaQuery(`(min-width: 768px)`);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    const ctx = gsap.context(() => {
      gsap.timeline({
        scrollTrigger: {
          trigger: "#work .cont_wrap",
          start: isDesktop ? "top top" : "top top",
          end: ref.current.scrollWidth,
          pin: "#work .cont_wrap",
          invalidateOnRefresh: true,
          scrub: 1,
          snap:{
            snapTo: 1/(ref.current.length - 1),
            ineria: false,
            duration: {min: 0.1, max: 0.1}
          },
          anticipagePin: 1,
        }
      })
      .to('#work .panel_box', {
        x: -(ref.current.scrollWidth - window.innerWidth*0.8),
        ease: "none",
      });
    });
    return () => ctx.revert();
  }, [isDesktop]);

  function popupHandle(i){
    setShowPopup(true);
    setWorkId(i);
  }

  return(
    <WorkWrap className="section" id="work">
      <div className="cont_wrap">
        <div className="pin_box">
          <div className="title_box">
            <h3 className="work__title">Explore my work</h3>
          </div>
          <div className="panel_wrap" ref={ref}>
            <div className="panel_box">
              {workList.map((v, i) => (
                <div className={`panel panel${i}`} key={v.id} onMouseOver={() => setIsHover(true)} 
                onMouseOut={() => setIsHover(false)} onClick={() => popupHandle(i)}>
                  <div className="panel__conts">
                    <h4 className="panel__title">{v.title} <span className="panel__arrow">↗</span></h4>
                    <p className="panel__subtitle">{v.subtitle}</p>
                  </div>
                  <div className="panel__logo">{v.logo}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </WorkWrap>
  )
}

export default Work;
