import { useEffect } from "react";
import styled from "styled-components";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import useMediaQuery from "../hooks/useMediaQuery";

const ContactWrap = styled.div`
  .cont_wrap{overflow: hidden;}
  .title_box{text-align: center; font-size: 5vw; padding: 30vmax 0 30vmax;
    .contact__title{line-height: 1;}
    .contact__title2{font-family: ${({theme}) => theme.fontFamily.garamond}; line-height: 1;}
  }
  .info_box{display: flex; padding: 60px 3.85vw; justify-content: space-around; font-size: 12px; line-height: 1.5em;
    .email{text-decoration: underline; transition-duration: 0.3s;
      &:hover{opacity: .7;}
    }
    .link_info{
      .link{display: inline-block; padding: 0 5px; border: 1px solid #1b1b1b; border-radius: 30px; transition-duration: 0.3s;
        &:hover{background: #1b1b1b; color: #fff;}
      }
    }
  }
`;

function Contact({setIsHover}){
  let isDesktop = useMediaQuery(`(min-width: 768px)`);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    const ctx = gsap.context(() => {
      gsap.timeline({
        scrollTrigger: {
          trigger: "#contact .title_box",
          start: isDesktop ? "top top" : "top 60%",
          end: isDesktop ? "bottom 50%" : "bottom bottom",
          scrub: 1,
          pin: true
        }
      })
      .fromTo('#contact .title_box', {backgroundColor: '#fefff4', backgroundImage: `url('/assets/bg_noise.png')`}, {backgroundColor: '#000000', ease: 'none', duration: 5, delay: 2}, 0)
      .fromTo('#contact .contact__title', {x: '-40%'}, {x: '0%', color: '#ffffff', ease: 'none', duration: 7},0)
      .fromTo('#contact .contact__title2', {x: '30%'}, {x: '0%', color: '#ffffff', ease: 'none', duration: 7},0)

      gsap.timeline({
        scrollTrigger:{
          trigger: "#contact .cont_box",
          start: "top 100%",
          end: "bottom bottom",
          scrub: 1,
        }
      })
      .fromTo('#contact .info_box', {y: '15.63vw'}, {y: '0', ease: 'none',duration: 5},0)
    });
    
    return () => ctx.revert();
  }, [isDesktop])

  return(
    <ContactWrap className="section" id="contact">
      <div className="cont_wrap">
        <div className="title_box">
          <h3 className="contact__title">Get in</h3>
          <h3 className="contact__title2">touch</h3>
        </div>
        <div className="cont_box">
          <div className="info_box">
            <div className="basic_info">
              Dohee Lee <br />
              Web Publisher &amp; UI Developer <br />
              Since 2018
            </div>
            <div className="email_info">
              Job Offer <br />
              <a href="mailto:dohee.biz@gmail.com" className="email" onMouseOver={() => setIsHover(true)} 
                onMouseOut={() => setIsHover(false)}>dohee.biz@gmail.com</a>
            </div>
            <div className="link_info">
              <a href="https://github.com/doh-lee" className="link" target="_blank" rel="noreferrer" onMouseOver={() => setIsHover(true)} 
                onMouseOut={() => setIsHover(false)}>github</a>
            </div>
          </div>
        </div>
      </div>
    </ContactWrap>
  )
}

export default Contact;
