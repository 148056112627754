import { keyframes } from "styled-components"

const fontSize = {
  default: `16px`,
  small: `14px`,
  medium: `18px`,
  large: `20px`,
}

const fontFamily = {
  default : `'Nunito Sans', 'Pretendard', sans-serif`,
  pretendard : `'Pretendard', sans-serif`,
  garamond : `'EB Garamond', 'Pretendard', sans-serif`
}

const color = {
  green : `#204829`,
  yellow: `#fefff4`,
  point1: `#d4dfb4`,
}

const circle = keyframes`
  0% { transform: rotate(0); } 
  100% { transform: rotate(360deg); } 
`;

const noise = keyframes`
  0% {
    transform: translate3d(0,9rem,0);
  }
  10% {
    transform: translate3d(-1rem,-4rem,0);
  }
  20% {
    transform: translate3d(-8rem,2rem,0);
  }
  30% {
    transform: translate3d(9rem,-9rem,0);
  }
  40% {
    transform: translate3d(-2rem,7rem,0);
  }
  50% {
    transform: translate3d(-9rem,-4rem,0);
  }
  60% {
    transform: translate3d(2rem,6rem,0);
  }
  70% {
    transform: translate3d(7rem,-8rem,0);
  }
  80% {
    transform: translate3d(-9rem,1rem,0);
  }
  90% {
    transform: translate3d(6rem,-5rem,0);
  }
  to {
    transform: translate3d(-7rem,0,0);
  }
`;

const animation = {
  circle,
  noise
}

const theme ={
  fontSize,
  fontFamily,
  color,
  animation,
}

export default theme;