import styled from 'styled-components';
import { workList } from "../workList";

const PopupWrap = styled.div`
  width: 100%; height: 100%; background: #253b33; color: #fff; position: fixed; top: 0; left: 0; z-index: 200; overflow-y: auto;
  .btn__close{all: unset; font-size: 0; width: 20px; height: 20px; position: fixed; top: 20px; right: 40px; z-index: 10; mix-blend-mode: difference;
    &:before{content: ''; display: block; width: 100%; height: 2px; background: #fff; position: absolute; top: 50%; left: 0; transform: rotate(45deg);}
    &:after{content: ''; display: block; width: 100%; height: 2px; background: #fff; position: absolute; top: 50%; left: 0; transform: rotate(135deg);}
  }
  .popup_box{padding: 20px;
    .section-1{width: 100%; position: sticky; top: 20vh; left: 0;
      .cont_box{max-width: 33.33%; margin: auto;}
      .popup__date{font-size: 18px; padding-bottom: 4%; border-bottom: 1px solid #fff;}
      .popup__title{font-size: 70px; font-weight: bold; margin-top: 45px;}
      .popup__subtitle{margin-top: 35px; font-size: 24px; word-break: keep-all;}
      .popup__link{display: inline-block; margin-top: 20px; font-size: 14px; font-weight: 300; opacity: 0.8;}
      .popup__nolink{display: inline-block; margin-top: 20px; font-size: 14px; font-weight: 300; opacity: 0.8;}
    }
    .section-2{margin-top: 50vh; position: relative; z-index: 1; background: #253b33;
      .img-container{display: flex; justify-content: center; align-items: flex-start; margin: auto;
        .pc_img{width: calc(75% - 10px); margin-right: 10px;}
        .pc_img:last-child{width: 100%;}
        .mo_img:first-child{width: 100%; text-align: center;}
      }
    }
    .section-3{padding: 40px 0; position: relative; z-index: 1; background: #253b33;
      .text-container{width: 40%; margin: auto;}
      b{font-weight: 400;}
      p{font-size: 14px; line-height: 1.2em; margin-bottom: 4px; word-break: keep-all;}
      .contents__title{margin-bottom: 15px; font-size: 45px;}
      .contents__desc{margin-bottom: 30px;}
    }
    @media (max-width: 767px){
      .section-1 .cont_box{max-width: 80%;
        .popup__title{font-size: 44px;}
      }
      .section-2{margin-top: 35vh;
        .img-container{display: block;
          .pc_img{width: 100%; margin-right: 0; margin-bottom: 20px;}
          .mo_img{text-align: center;}
        }
      }
      .section-3 .text-container{width: 100%;
        .contents__title{font-size: 30px;}
      }
    }
  }
`;

function Popup(props) {
  const { onClose, workId, setIsHover } = props;
  return (
    <PopupWrap className="popup_wrap">
      <button className="btn__close" onClick={()=> onClose(false)} onMouseOver={() => setIsHover(true)} onMouseOut={() => setIsHover(false)}>닫기</button>
      <div className="popup_box">
        <div className="popup__sec section-1">
          <div className="cont_box">
            <p className="popup__date">{workList[workId].date}</p>
            <h4 className="popup__title">{workList[workId].title}</h4>
            <p className="popup__subtitle">{workList[workId].subtitle}</p>
            {workList[workId].url.includes('http') ? <a href={workList[workId].url} className="popup__link" target="_blank" rel="noreferrer" onMouseOver={() => setIsHover(true)} onMouseOut={() => setIsHover(false)}>바로가기 ↗</a> : <span className="popup__nolink">{workList[workId].url}</span>}
          </div>
        </div>
        <div className="popup__sec section-2">
          <div className="img-container">
            {workList[workId].img.pc !== undefined ? <div className="pc_img"><img src={workList[workId].img.pc} alt={workList[workId].title} /></div> : ''}
            {workList[workId].img.mo !== undefined ? <div className="mo_img"><img src={workList[workId].img.mo} alt={workList[workId].title} /></div> : ''}
          </div>
        </div>
        <div className="popup__sec section-3">
          <div className="text-container">
            <h5 className="contents__title">{workList[workId].content.title}</h5>
            <p className="contents__desc">{workList[workId].content.description}</p>
            <p className="contents__date"><b>작업기간_</b> {workList[workId].date}</p>
            <p className="contents__cont"><b>기여도_</b> {workList[workId].content.contribution}</p>
            <p className="contents__skil"><b>개발환경_</b> {workList[workId].content.skill}</p>
            <p className="contents__resp"><b>반응형_</b> {workList[workId].content.responsive}</p>
          </div>
        </div>
      </div>
    </PopupWrap>
  )
}

export default Popup;