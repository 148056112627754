import Header from './Header';
import Main from './Main'
import About from './About';
import Work from './Work';
import Contact from './Contact';
import styled from "styled-components";
// import  Scrollbar  from 'smooth-scrollbar';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect, useRef, useState } from 'react';
import useMediaQuery from '../hooks/useMediaQuery';

const ContentsWrap = styled.div`
  width: 100%;
`;

function Container ({setIsHover, setShowPopup, setWorkId}) {
  const [anchor, setAnchor] = useState(null);
  const ref = useRef(null);
  let isDesktop = useMediaQuery(`(min-width: 768px)`);
  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    if (anchor !== null){
      let scrollPos = document.querySelector(`#${anchor}`).offsetTop;
      if (anchor === 'contact'){
        const endPos = document.body.scrollHeight;
        window.scrollTo({top: endPos, behavior: 'smooth'});
      }else{
        window.scrollTo({top: scrollPos, behavior: 'smooth'});
      }
    }
  },[anchor, isDesktop]);

  return(
    <ContentsWrap className="wrap" ref={ref}>
      <Header setAnchor={setAnchor} setIsHover={setIsHover} />
      <Main />
      <About />
      <Work setIsHover={setIsHover} setShowPopup={setShowPopup} setWorkId={setWorkId} />
      <Contact setIsHover={setIsHover} />
    </ContentsWrap>
  )
}

export default Container;