import { useEffect } from "react";
import styled from "styled-components";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import useMediaQuery from "../hooks/useMediaQuery";

const AboutWrap = styled.div`
  .cont_wrap{display: flex; height: 120vh; flex-direction: column; justify-content: center;
    .text_box {display: inline-block; margin: 0 auto 20vh; text-align: center;
      p{font-size: 4.58vw;}
    }
    .ani_box{
      .icon_box{width: 64px; height: 64px; line-height: 64px; margin: 0 auto 40px; text-align: center; font-size: 12px; font-weight: bold; position: relative;}
      .icon_setting{display: block; width: 100%; height: 100%; position: absolute; top: 0; left: 0; background: url('/assets/global-network.png') no-repeat center center;
      }
      .text4{font-size: calc(16px + 0.5vw); text-align: center;}
    }
  }
  @media (max-width: 767px) {
    .cont_wrap{height: auto;
      .text_box{margin: 20vh auto 10vh;}
      .ani_box{margin: 0 auto 20vh;}
    }
  }
`;

function About(){
  let isDesktop = useMediaQuery(`(min-width: 768px)`);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    const ctx = gsap.context(() => {
      gsap.timeline({
        scrollTrigger: {
          trigger: '#about .text_box',
          start: isDesktop ? "top 60%" : "top 60%",
          end: "bottom 40%",
          scrub: 1,
        }
      })
      .fromTo("#about .text1", { x: "50%" }, { x: "0" }, 0)
      .fromTo("#about .text2", { x: "-50%" }, { x: "0" }, 0)
      .fromTo("#about .text3", { x: "50%" }, { x: "0" }, 0)

      gsap.timeline({
        scrollTrigger: {
          trigger: '#about .cont_wrap',
          start: "top 60%",
          end: "bottom top",
          scrub: 1,
        }
      })
      .fromTo("#about .icon_setting", { rotation: "0" }, { rotation: "180" }, 0)
    });

    return () => ctx.revert();
  }, [isDesktop])
  return(
    <AboutWrap className="section" id="about">
      <div className="cont_wrap">
        <div className="text_box">
          <p className="text1"><span className="garamond">Creative</span> Approach</p>
          <p className="text2">Attention to <span className="garamond">Detail</span></p>
          <p className="text3">Neat animation</p>
        </div>
        <div className="ani_box">
          <div className="icon_box"><span className="icon_setting"></span></div>
          <p className="text4">A strong project is created <br />by deep <span className="garamond">collaboration</span>.</p>
        </div>
      </div>
    </AboutWrap>
  )
}

export default About;
