import React from "react";
import styled from "styled-components";
// import { Link } from "react-scroll";

const HeaderWrap = styled.div`
  position: fixed; width: 100%; padding: 15px; mix-blend-mode: difference; z-index: 100;
  &.active .gnb_wrap .nav> li> .anchor{color: #222;}
  &.active .logo_wrap .logo text{fill: #222;}
  .logo_wrap{width: 120px; position: relative;
    .logo{width: 120px; height: 120px; position: absolute; top: 0; left: 0; animation: ${({theme}) => theme.animation.circle} 10s infinite linear;
      text{fill: #fff; font-weight: 200; font-family: ${({theme}) => theme.fontFamily.garamond};
      transition-duration: 0.3s;}
    }
  }
  .gnb_wrap{position: absolute; top:0; right: 0; padding: 15px;
    .nav{display: flex; position: relative; z-index: 1;
      &:hover>li>.anchor.active:after{opacity: 0;}
      >li{
        >.anchor{display: inline-block; padding: 10px 15px; color: #fff; text-transform: uppercase; font-size: 12px; position: relative; transition-duration: 0.3s;
          &:after{content: ''; opacity: 0; width: 100%; height: 30px; position: absolute; top: -2px; left: -2px; transform: skew(-15deg, -10deg); background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' stroke='%23fff'%3E%3Cellipse cx='50%' cy='50%' rx='40%' ry='45%' /%3E%3C/svg%3E"); transition: opacity linear 0.2s;}
          &.active:after{opacity: 0.6; z-index: -1;}
        }
      }
    }
  }
`;


function Header(props){
  
  const menus = [
    {link:"home", menu: "Home"},
    {link:"about", menu: "About"},
    {link:"work", menu: "Work"},
    {link:"contact", menu: "Contact"},
  ];

  return(
    <HeaderWrap className="header">
      <div className="logo_wrap">
        <h1 className="logo">
          <svg viewBox="0 0 300 300">
            <defs>
              <path id="circle" d="M 50 150 A 50 50 0 1 1 250 150 A 50 50 0 1 1 50 150 "></path>
            </defs>
            <text fontSize="46">
              <textPath xlinkHref="#circle">dohee’s portfolio &middot; beyond creative &middot;</textPath>
            </text>
          </svg>
        </h1>
      </div>
      <div className="gnb_wrap">
        <ul className="nav">
          {menus.map((v) => (
            <li key={v.link}>
              <a href={`#${v.link}`} className={`anchor ${v.link}`} 
              onMouseOver={() => props.setIsHover(true)} 
              onMouseOut={() => props.setIsHover(false)}
              onClick={(e) => {e.preventDefault(); props.setAnchor(v.link)}}>{v.menu}</a>
            </li>
            ))}
        </ul>
      </div>
      
    </HeaderWrap>
  )
}
export default Header;